.practice {
    overflow: hidden;
    .page-mv {
        background: url(../images/practice/mv.jpg) no-repeat;
        background-size: cover;
        background-position: center top;
        h2 {
            color: white;
            &::after {
                content: none;
            }
        }
    }

    h2 {
        position: relative;
        font-size: 32px;
        @include view-at(tab) {
            font-size: 25px;
        }
        &::after {
            content: "";
            width: 250%;
            display: block;
            height: 1px;
            background: black;
            position: absolute;
            left: -80px;
            @include view-at(tab) {
                left: -30px;
            }
        }
        &.sub {
            &::after {
                left: auto;
                right: -80px;
                @include view-at(tab) {
                    right: -30px;
                }
            }
        }
    }
}