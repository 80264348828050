/* --------------------------
幅指定
-------------------------- */

@for $i from 0 through 10 {
  $width: (10% * $i);
  .u-w#{$i * 10} {width: $width; display: block;}
}

@include view-at(pc) {
  @for $i from 0 through 10 {
    $width: (10% * $i);
    .u-w#{$i * 10}--pc {width: $width; display: block;}
  }
}

@include view-at(laptop) {
  @for $i from 0 through 10 {
    $width: (10% * $i);
    .u-w#{$i * 10}--laptop {width: $width; display: block;}
  }
}

@include view-at(tab) {
  @for $i from 0 through 10 {
    $width: (10% * $i);
    .u-w#{$i * 10}--tab {width: $width; display: block;}
  }
}

@include view-at(sp) {
  @for $i from 0 through 10 {
    $width: (10% * $i);
    .u-w#{$i * 10}--sp {width: $width; display: block;}
  }
}

.u-h100 {
  height: 100%;
}