@charset "UTF-8";
.top {
    &-mv {
        position: relative;
        .slick-mv {
            margin-bottom: 0 !important;
            &-item {
                height: 100vh;
                background: url(../images/top/mv01.jpg);
                background-size: cover;
                background-position: bottom left;
                @include view-at(tab) {
                    height: 80vh;
                    background: url(../images/top/mv01-sp.jpg);
                    background-size: cover;
                    background-position: bottom left;
                }
                @include view-at(sp) {
                    height: 100vh;
                }
                
                .text{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    width: 100%;
                    text-align: center;
                    &-inner {
                        @include view-at(sp) {
                            padding: 0 10px;
                        }
                        &.white {
                            color: white;
                            filter: drop-shadow(0px 0px 5px black);
                        }
                        h1 {
                            font-size: 40px;
                            margin-bottom: 20px;
                            font-family: $serif;
                            font-weight: bold;
                            letter-spacing: 0.2em;
                            @include view-at(sp) {
                                font-size: 30px;
                            }
                        }
                        h2 {
                            font-size: 30px;
                            margin-bottom: 20px;
                            font-family: $serif;
                            font-weight: bold;
                            line-height: 1.5em;
                            @include view-at(sp) {
                                font-size: 20px;
                            }
                        }
                        p {
                            font-size: 20px;
                            line-height: 2em;
                            font-family: $serif;
                            font-weight: bold;
                            @include view-at(sp) {
                                font-size: 12px;
                            }
                        }
                        p.en {
                            font-size: 30px;
                            font-family: relation-one, sans-serif;
                            font-weight: 400;
                            // color: black;
                            @include view-at(sp) {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }

            .slick-slide {
                &:nth-child(2) {
                    .slick-mv-item {
                        background: url(../images/top/mv02.jpg);
                        background-size: cover;
                        background-position: bottom left;
                        @include view-at(tab) {
                            background: url(../images/top/mv02-sp.jpg);
                            background-color: #8d8b8b;
                            background-size: cover;
                            background-position: bottom left;
                        }
                    }
                }
                &:nth-child(3) {
                    .slick-mv-item {
                        background: url(../images/top/mv03.jpg);
                        background-size: cover;
                        background-position: bottom left;
                        @include view-at(tab) {
                            background: url(../images/top/mv03-sp.jpg);
                            background-color: #8d8b8b;
                            background-size: cover;
                            background-position: bottom left;
                        }
                    }
                }
                &:nth-child(4) {
                    .slick-mv-item {
                        background: url(../images/top/mv04.jpg);
                        background-size: cover;
                        background-position: bottom left;
                        @include view-at(tab) {
                            background: url(../images/top/mv04-sp.jpg);
                            background-color: #8d8b8b;
                            background-size: cover;
                            background-position: bottom left;
                        }
                    }
                }
            }

            .slick-dots {
                top: 170px;
                right: 15px;
                width: auto;
                @include view-at(sp) {
                    top: 50px;
                    right: 5px;
                }
                li {
                    width: 10px;
                    height: 15px;
                    display: block;
                    button:before {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: white;
                        opacity: 0.5;
                    }
                    &.slick-active {
                        button:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &-news {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 20px;
            padding-right: 150px;
            background: rgba(255,255,255,.8);
            display: block;
            font-size: 14px;
            font-weight: bolder;
            @include view-at(sp) {
                width: 100%;
                font-size: 12px;
                padding: 15px 10px;
                position: relative;
                background: #F5F5F5;
            }
            
            tbody {
                @include view-at(sp) {
                    display: block;
                }  
            }
            tr {
                @include view-at(sp) {
                    display: block;
                    width: 100%;
                }
            }
            th {
                border-right: 1px solid;
                padding-right: 20px;
                @include view-at(sp) {
                    display: block;
                    width: 100%;
                    border-right: none;
                    border-bottom: 1px solid;
                    padding-right: 0;
                    padding-bottom: 10px;
                    text-align: center;
                }
            }
            td {
                padding-left: 20px;
                font-family: $serif;
                @include view-at(sp) {
                    display: block;
                    width: 100%;
                    padding-left: 0;
                    padding-top: 15px;
                    position: relative;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    width: 30px;
                    height: 1px;
                    background: #333;
                    transition: all .3s;
                    @include view-at(sp) {
                        top: 67%;
                        right: 10px;
                    }
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 44%;
                    right: 18px;
                    width: 12px;
                    height: 1px;
                    background: #333;
                    transform: rotate(35deg);
                    transition: all .3s;
                    @include view-at(sp) {
                        top: 54%;
                        right: 8px;
                    }
                }
            }
        }

        &-cv {
            position: absolute;
            right: 10px;
            bottom: 10px;
            @include view-at(tab) {
                bottom: 60px;
            }
            @include view-at(sp) {
                position: absolute;
                right: 0;
                bottom: 0;
            }
            a.policy {
                padding: 15px 30px;
                background: white;
                display: block;
                font-family: $serif;
                font-size: 14px;
                font-weight: bolder;
                width: 300px;
                margin-bottom: 10px;
                position: relative;
                @include view-at(sp) {
                    font-size: 12px;
                    padding: 15px;
                    width: 200px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    width: 30px;
                    height: 1px;
                    background: #333;
                    -webkit-transition: all .3s;
                    transition: all .3s;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 43%;
                    right: 18px;
                    width: 12px;
                    height: 1px;
                    background: #333;
                    -webkit-transform: rotate(35deg);
                    transform: rotate(35deg);
                    -webkit-transition: all .3s;
                    transition: all .3s;
                }
            }

            a.special {
                background: #8d8b8b;
                padding: 30px;
                height: 200px;
                display: block;
                font-family: $serif;
                font-size: 18px;
                font-weight: bolder;
                width: 300px;
                margin-bottom: 10px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: white;
                @include view-at(sp) {
                    font-size: 12px;
                    padding: 15px;
                    width: 200px;
                    height: 150px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    width: 30px;
                    height: 1px;
                    background: white;
                    -webkit-transition: all .3s;
                    transition: all .3s;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 23px;
                    right: 18px;
                    width: 12px;
                    height: 1px;
                    background: white;
                    -webkit-transform: rotate(35deg);
                    transform: rotate(35deg);
                    -webkit-transition: all .3s;
                    transition: all .3s;
                }
            }
        }
    }

    &-guide {
        overflow: hidden;
        &-txt {
            padding: 0 80px;
            @include view-at(tab) {
                padding: 0;
                padding-left: 20px;
            }
            @include view-at(sp) {
                padding: 0 20px;
            }
        }
    }

    &-news {
        h2 {
            border-bottom: 1px solid $color-gray-light;
            padding-bottom: 20px;
            small {
                display: inline-block;
            }
        }
        table {
            display: block;
            tbody {
                display: block;
                tr {
                    border-bottom: 1px solid $color-gray-light;
                    padding: 15px;
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 14px;
                    @include view-at(sp) {
                        font-size: 12px;
                    }
                    th {
                        padding-right: 15px;
                        width: 100px;
                    }
                    td {
                        width: calc(100% - 100px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
        .facebook-wrapper {
            max-width: 500px; /* Facebook Page Plugin の最大幅 */
            margin: 0 auto;
            & > .fb-page {
                width: 100%;
            }
            & > .fb-page > span,
            & iframe {
                width: 100% !important;
            }
        }
        
    }

    &-link {
        &-reservation {
            background: url(../images/top/reservation-bg.png) no-repeat;
            background-size: cover;
            color: white;
            padding: 80px;
            @include view-at(sp) {
                display: block;
                padding: 80px 20px;
            }
            
            h3 {
                color: black;
            }
            &-inner {
                padding: 40px;
                background: rgba(0,0,0,.6);
                margin-bottom: 40px;
                @include view-at(sp) {
                    padding: 30px 20px;
                }
            }
            &-list {
                li {
                    margin-bottom: 5px;
                    a {
                        background: white;
                        display: block;
                        padding: 15px 10px;
                        padding-right: 80px;
                        line-height: 1.3em;
                        font-size: 14px;
                        position: relative;
                        cursor: pointer;
                        @include view-at(sp) {
                            font-size: 12px;
                            padding-right: 40px;
                        }
                        &::after {
                            content: '';
                            width: 10px;
                            height: 10px;
                            display: block;
                            border-top: solid 2px black;
                            border-right: solid 2px black;
                            transform: rotate(45deg) translateY(-50%);
                            position: absolute;
                            top: 50%;
                            right: 15px;
                        }
                    }
                }
            }
        }
        &-panel {
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 25%;
                    @include view-at(tab) {
                        width: calc(100%/3);
                    }
                    @include view-at(sp) {
                        width: 50%;
                    }
                    a {
                        padding-top: 100%;
                        position: relative;
                        display: block;
                        &::after {
                            content: "";
                            display: block;
                            background: url(../images/top/grid01.jpg) no-repeat;
                            background-size: cover;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: all 0.5s;
                        }
                        &:hover {
                            opacity: 1;
                            &::after {
                                filter: brightness(0.5);
                            }
                        }
                        h2 {
                            text-align: center;
                            color: white;
                            z-index: +1;
                            text-shadow: 0 0 10px black;
                            font-size: 30px;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 100%;
                            text-align: center;
                            small {
                                text-shadow: 0 0 5px black;
                            }
                            @include view-at(tab) {
                                font-size: 26px;
                                small {
                                    font-size: 14px;
                                }
                            }
                            @include view-at(sp) {
                                font-size: 18px;
                                small {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        a {
                            &::after {
                                background: url(../images/top/grid02.jpg) no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                    &:nth-child(3) {
                        a {
                            &::after {
                                background: url(../images/top/grid03.jpg) no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                    &:nth-child(4) {
                        a {
                            &::after {
                                background: url(../images/top/grid04.jpg) no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                    &:nth-child(5) {
                        a {
                            &::after {
                                background: url(../images/top/grid05.jpg) no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                    &:nth-child(6) {
                        a {
                            &::after {
                                background: url(../images/top/grid06.jpg) no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                    &:nth-child(7) {
                        a {
                            &::after {
                                background: url(../images/top/grid07.jpg) no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                    &:nth-child(8) {
                        a {
                            &::after {
                                background: url(../images/top/grid08.jpg) no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                }
            }
        }
        &-pro {
            img {
                max-width: auto;
                width: 100%;
            }
        }
        &-question {
            display: block;
            padding: 40px;
            text-align: center;
            height: 250px;
            position: relative;
            background: url(../images/top/question.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            color: white;
            font-weight: bold;
            color: white;
            font-weight: bold;
            filter: drop-shadow(0px 0px 5px gray);
            @include view-at(sp) {
                padding: 30px 20px 60px;
                height: auto;
                background-size: 200%;
                margin-bottom: 40px;
            }
            &::before {
                content: '';
                /* ↓暗いオーバーレイを半透明で配置 */
                background-color: rgba(0, 0, 0, 0.2);
                /* ↓全体を覆うように配置 */
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                transition: all 0.5s;
            }
            h2,p {
                filter: drop-shadow(0px 0px 5px black);
            }
            span {
                position: absolute;
                bottom: -20px;
                left: 50%;
                transform: translateX(-50%);
                transition: all 0.5s;
                @include view-at(sp) {
                    width: 90%;
                }
            }
            &:hover {
                opacity: 1;
                filter: none;
                &::before {
                    background-color: rgba(0, 0, 0, 0.6);
                }
                span {
                    background: black;
                    color: white;
                    &::before, &::after {
                        background: white;
                      }
                }
            }
            &.manner {
                background: url(../images/manner/mv.jpg) no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
    }
}

// カレンダーカスタマイズ
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    text-align: center;
    border: 1px solid white !important;
    background: no-repeat !important;
}
.ui-widget.ui-widget-content {
    border: none !important;
}
.ui-widget-header {
    border: none !important;
    background: none !important;
}
/* 日曜日のカラー設定 */
td.ui-datepicker-week-end:last-child a.ui-state-default{
    color: #f00!important;       /* 文字色を設定 */
    &.ui-state-active {
        color: #fff !important;
    }
}
/* 土曜日のカラー設定 */
td.ui-datepicker-week-end a.ui-state-default{
    color: #00f!important;       /* 文字色を設定 */
    &.ui-state-active {
        color: #fff !important;
    }
}
/* ホバー時の動作 */
td.ui-datepicker-week-end a.ui-state-hover{
    opacity: 0.8;
}
/* 当日を示す色はそのまま */
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight{
    background-color: #fffa90!important;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
    background: black !important;
    color: #fff !important;
}
.ui-datepicker th {
    &:nth-last-child(1) {
        color: #f00 !important;
    }
    &:nth-last-child(2) {
        color: #00f !important;
    }
}