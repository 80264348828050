.gps {
    .page-mv {
        background: url(../images/gps/mv.jpg) no-repeat;
        background-size: cover;
        background-position: center top;
        @include view-at(sp) {
            background: url(../images/gps/mv-sp.jpg) no-repeat;
            background-size: cover;
        }
        h2 {
            color: white;
        }
    }
    &-black {
        background: black;
        position: relative;
        color: white;
        padding-top: 120px;
        @include view-at(sp) {
            padding-top: 80px;
        }
        h2 {
            position: absolute;
            top: -20px;
            left: -5px;
            font-size: 60px;
            margin-bottom: 40px;
            color: white;
            @include view-at(sp) {
                font-size: 40px;
                top: -12px;
            }
            small {
                padding-left: 30px;
                @include view-at(sp) {
                    padding-left: 15px;
                }
            }
        }
    }
    &-white {
        position: relative;
        padding-top: 120px;
        @include view-at(sp) {
            padding-top: 80px;
        }
        h2 {
            position: absolute;
            top: -20px;
            left: -5px;
            font-size: 60px;
            margin-bottom: 40px;
            @include view-at(sp) {
                font-size: 40px;
                top: -12px;
            }
            small {
                padding-left: 30px;
                @include view-at(sp) {
                    padding-left: 15px;
                }
            }
        }
        .grid {
            display: grid;
            grid-template-columns: 1.3fr 1fr 1.3fr;
            grid-template-rows: 1fr 1fr;
            height: 400px;
            @include view-at(sp) {
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1.5fr 1fr 1.5fr;
                height: 600px;
            }
            li {
                background: gray;
                margin: 5px;
                &:nth-child(1) {
                    grid-row: 1 / 3;
                    grid-column: 1;
                    background: url(../images/gps/grid01.jpg) no-repeat;
                    background-size: cover;
                    background-position: center;
                    @include view-at(sp) {
                        grid-row: 1 / 2;
                        grid-column: 1 / 3;
                    }
                }
                &:nth-child(2) {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                    background: url(../images/gps/grid02.jpg) no-repeat;
                    background-size: cover;
                    background-position: center;
                    @include view-at(sp) {
                        grid-row: 2 / 3;
                        grid-column: 1 / 2;
                    }
                }
                &:nth-child(3) {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                    background: url(../images/gps/grid03.jpg) no-repeat;
                    background-size: cover;
                    background-position: center;
                    @include view-at(sp) {
                        grid-row: 2 / 3;
                        grid-column: 2 / 3;
                    }
                }
                &:nth-child(4) {
                    grid-row: 1 / 3;
                    grid-column: 3 / 4;
                    background: url(../images/gps/grid04.jpg) no-repeat;
                    background-size: cover;
                    background-position: center;
                    @include view-at(sp) {
                        grid-row: 3 / 4;
                        grid-column: 1 / 3;
                    }
                }
            }
        }
    }
}