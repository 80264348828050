.p-header {
  width: 100%;
  z-index: 1000;
  position: absolute;
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 $contents-padding;
    background: white;
    @include view-at(tab) {
      padding: 0;
      display: none;
    }
    .p-header-logo {
      a {
        img {
          width: 220px;
          height: auto;
        }
      }
    }

    nav {
      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
          padding-left: 40px;
          
          a {
            font-size: 12px;
            font-weight: 600;
            position: relative;
            padding-left: 10px;
            &::before {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-left: 4px solid black;
              border-top: 3px solid transparent;
              border-bottom: 3px solid transparent;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }

  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 $contents-padding;
    padding-right: 0;
    background: black;
    @include view-at(laptop) {
      padding: 0 10px 0 0;
    }
    @include view-at(tab) {
      padding: 0;
    }
  }

  &-logo {
    text-align: center;
    font-size: 0;
    padding: 10px;
    @include view-at(tab) {
      position: absolute;
      width:100%;
      top: 0;
      background: black;
    }
    &.fixed {
      position: fixed;
      animation: DownAnime 0.5s forwards;
    }

    a {
      display: inline-block;
      line-height: 1;
      font-size: 0;
      pointer-events: none;

      @include view-at(pc) {
        img {
          width: 220px;
          height: auto;
        }
      }
      @include view-at(sp) {
        width: 160px;
        height: auto;
      }
    }
  }

  &-cv {
    background-color: #FF731D;
    background-image: url(../images/common/icon-calendar.png);
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px;
    color: white;
    font-family: $noto;
    font-weight: 900;
    padding: 15px 30px;
    padding-left: 50px;
    display: block;
    width: 250px;
    letter-spacing: 0.1em;
    text-align: center;
    @include view-at(laptop) {
      font-size: 14px;
    }
    @include view-at(tab) {
      width: calc(100% - 64px);
      text-align: center;
      padding: 20px 30px;
      color: white;
    }
  }

  &-bottom {
    position: absolute;
    bottom: -46px;
    right: 0;
    @include view-at(laptop) {
      bottom: -44px;
    }
    @include view-at(tab) {
      background: white;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      opacity: 0;
      height: 0;
      overflow: hidden;
      animation: slideIn 0.5s forwards;
      &.fixed{
        opacity: 0;
        height: auto;
        overflow: visible;
        animation: UpAnime 0.5s forwards;
        box-shadow: 0 0 2px rgba(0,0,0,0.4);
        background: white;
      }
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-100%); 
    }
    to {
      transform: translateX(0); 
    }
  }
}

/*==ふわっと出現させるためのCSS*/
/*　下に下がる動き　*/
#header.fixed{
  position: fixed;
  width:100%;
  animation: DownAnime 0.5s forwards;
  background: black;
}
@keyframes DownAnime{
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes UpAnime{
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}