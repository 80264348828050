.guide {
    .page-mv {
        background: url(../images/guide/mv.jpg) no-repeat;
        background-size: cover;
        background-position: center top;
    }
    &-map {
        @include view-at(sp) {
            .simplebar-scrollbar::before {
                background-color: $color-green;
            }
            .simplebar-scrollbar.simplebar-visible::before {
                background-color: $color-green;
                opacity: 1;
            }
            .simplebar-track {
                background-color: #ccc;
                border-radius: 100px;
            }
            &-wrap {
                width: 600px;
                padding-bottom: 20px;
            }
        } 
    }
    &-copy {
        overflow: hidden;
        &-wrap {
            padding-left: 80px;
            @include view-at(tab) {
                padding-left: 20px;
            }
            @include view-at(sp) {
                padding: 0 15px;
            }
        }
    }
    &-course {
        tr {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            @include view-at(sp) {
                display: block;
            }
            th {
                background: black;
                color: white;
                font-size: 20px;
                width: 200px;
                text-align: center;
                padding: 30px 20px;
                font-family: $noto;
                font-weight: bold;
                height: 100%;
                display: block;
                @include view-at(sp) {
                    width: 100%;
                    font-size: 16px;
                    padding: 20px;
                }
            }
            td {
                width: calc(100% - 200px);
                padding-left: 20px;
                line-height: 1.5em;
                font-size: 14px;
                display: block;
                font-family: $serif;
                @include view-at(sp) {
                    width: 100%;
                    padding: 15px;
                }
            }
        }
    }
    &-hole {
        &-nav {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
        
            @include view-at(sp) {
              flex-wrap: wrap;
              margin: 0 -5px 5px;
            }
            &-wrap {
              background: black;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 20px;
              @include view-at(tab) {
                display: block;
              }
              &-inner {
                display: flex;
                align-items: center;
                justify-content: center;
                &:nth-child(1) {
                  margin-right: 20px;
                }
                @include view-at(tab) {
                  &:nth-child(1) {
                    margin-right: 0;
                    margin-bottom: 10px;
                  }
                }
              }
              p {
                font-family: $noto;
                font-weight: bold;
                margin-bottom: 0;
                margin-right: 10px;
                @include view-at(tab) {
                  width: 120px;
                }
              }
            }
        
            &-item {
              padding: 0 5px;
              @include view-at(sp) {
                padding: 5px;
              }
              a {
                background-color: white;
                display: block;
                border-radius: 50px;
                width: 30px;
                height: 30px;
                text-align: center;
                text-decoration: none;
                color: black;
                font-weight: $bold;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $garamond;
        
                @include view-at(sp) {
                  width: 25px;
                  height: 25px;
                }
        
                &.current {
                  background: #009348;
                  color: white;
                }
                &:hover {
                  opacity: 1;
                  background: #009348;
                  color: white;
                }
              }
            }
        }
    
        &-contents {
        &-item {
            background-color: $color-white;
        }
        .inner {
            display: flex;
            flex-wrap: wrap;
            > li {
            &:nth-child(1) {
                width: 35%;
                @include view-at(tab) {
                    width: 100%;
                }
            }
            &:nth-child(2) {
                width: 65%;
                @include view-at(tab) {
                    width: 100%;
                }
            }
            }
            .slick {
            margin: 0;
            padding: 0;
            line-height: 0;
            position: relative;
            p {
                font-size: 12px;
                position: absolute;
                bottom: 10px;
                left: 20px;
                font-family: $garamond;
                color: white;
                font-weight: bold;
                @include view-at(sp) {
                font-size: 10px;
                bottom: 5px;
                left: 10px;
                line-height: 1;
                }
            }
            .slick-dots {
                bottom: 20px;
                @include view-at(sp) {
                bottom: 10px;
                }
                li {
                    width: 10px;
                    height: 15px;
                    button:before {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: white;
                        opacity: 0.5;
                    }
                    &.slick-active {
                        button:before {
                            opacity: 1;
                        }
                    }
                }
            }
            .slick-prev {
                left: 20px;
                z-index: +1;
                top: 50%;
                &::before {
                content: '';
                width: 20px;
                height: 20px;
                display: block;
                border-top: solid 4px white;
                border-left: solid 4px white;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                }
            }
            .slick-next {
                right: 20px;
                top: 50%;
                &::before {
                content: '';
                width: 20px;
                height: 20px;
                display: block;
                border-top: solid 4px white;
                border-right: solid 4px white;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                }
            }
            }
            .text {
            padding: 0 40px;
            @include view-at(sp) {
                padding: 0 15px;
            }
            h2 {
                letter-spacing: 0;
                text-align: left;
                border-bottom: 1px solid;
                padding-bottom: 10px;
                margin-bottom: 30px;
                font-family: $garamond;
                @include view-at(sp) {
                    font-size: 22px;
                    margin-top: 20px;
                }
            }
            h3 {
                background: black;
                color: white;
                padding: 5px 10px;
                font-size: 16px;
                @include view-at(sp) {
                font-size: 14px;
                }
            }
            p {
                font-size: 14px;
                font-family: $serif;
                line-height: 2em;
                @include view-at(sp) {
                font-size: 12px;
                line-height: 1.5em;
                }
            }
            table {
                font-family: $garamond;
                width: 100%;
                tr {
                    font-size: 20px;
                    display: block;
                    @include view-at(sp) {
                        font-size: 16px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                    }
                }
                th {
                    width: 100%;
                    padding-bottom: 10px;
                    display: block;
                    @include view-at(sp) {
                        padding-left: 15px;
                        width: 150px;
                    }
                    &::before {
                        content: "";
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background: url(../images/common/icon.png);
                        background-size: 100%;
                        background-position: center;
                        margin-right: 10px;
                    }
                }
                td {
                    padding-bottom: 20px;
                    width: 100%;
                    display: block;
                    padding-left: 30px;
                    @include view-at(sp) {
                        padding-left: 15px;
                        width: calc(100% - 150px);
                        padding: 0;
                    }
                }
            }
            }
        }
        }
    }
}