.news {
    &-contents {
        h1 {
            font-size: 26px;
            line-height: 1.5em;
            letter-spacing: 0.08em;
            font-weight: bold;
            margin-bottom: 20px;
            @include view-at(sp) {
                font-size: 22px;
            }
        }
        h2 {
            font-size: 24px;
            line-height: 1.5em;
            letter-spacing: 0.08em;
            font-weight: bold;
            margin-bottom: 20px;
            @include view-at(sp) {
                font-size: 20px;
            }
        }

        h3 {
            font-size: 20px;
            line-height: 1.5em;
            letter-spacing: 0.08em;
            font-weight: bold;
            margin-bottom: 20px;
            @include view-at(sp) {
                font-size: 18px;
            }
        }
        h4 {
            font-size: 18px;
            line-height: 1.5em;
            letter-spacing: 0.08em;
            font-weight: bold;
            margin-bottom: 20px;
            @include view-at(sp) {
                font-size: 16px;
            }
        }
        h5 {
            font-size: 17px;
            line-height: 1.5em;
            letter-spacing: 0.08em;
            font-weight: bold;
            margin-bottom: 10px;
            @include view-at(sp) {
                font-size: 15px;
            }
        }
        h6 {
            font-size: 16px;
            line-height: 1.5em;
            letter-spacing: 0.08em;
            font-weight: bold;
            margin-bottom: 10px;
            @include view-at(sp) {
                font-size: 14px;
            }
        }
        p {
            line-height: 1.8em;
            margin-bottom: 20px;
        }
        a {
            color: $color-green;
            text-decoration: underline;
        }
        ul {
            li {
                margin-bottom: 10px;
                line-height: 1.5em;
                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
            }
        }
    }
}