/* --------------------------
テーブル
-------------------------- */

.c-table {
  width: 100%;
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  &.sub {
    @include view-at(sp) {
      width: 700px;
    }
  }
  &-wrap {
    @include view-at(sp) {
      padding-bottom: 20px;
      .simplebar-scrollbar::before {
          background-color: $color-green;
      }
      .simplebar-scrollbar.simplebar-visible::before {
          background-color: $color-green;
          opacity: 1;
      }
      .simplebar-track {
          background-color: #ccc;
          border-radius: 100px;
      }
    } 
  }

  thead {
    tr {
      border-top: 1px solid $color-gray-light;
      border-right: 1px solid $color-gray-light;
      border-left: 1px solid $color-gray-light;
      @include view-at(sp) {
        border: none;
      }
      th {
        padding: 15px;
        line-height: 1.5;
        font-weight: bold;
        width: 30%;
        background-color: #F5F5F5;
        vertical-align: top;
        border-bottom: 1px solid $color-gray-light;
        @include view-at(sp) {
          display: block;
          width: 100%;
          text-align: center;
          font-size: 14px;
          padding: 10px;
          border: none;
        }
      }
      td {
        font-weight: $bold;
        padding: 15px 30px;
        border-bottom: 1px solid $color-gray-light;
        font-family: $garamond;
        @include view-at(sp) {
          display: block;
          width: 100%;
          text-align: left;
          padding: 10px;
          font-size: 14px;
          border: none;
        }
      }
    }
  }

  tbody {
    tr {
      font-size: 14px;
      background: white;
      
      th {
        padding: 10px;
        line-height: 1.5;
        font-family: $noto;
        font-weight: bold;
        width: 20%;
        background-color: black;
        vertical-align: top;
        border-bottom: 1px solid white;
        color: white;
      }
      td {
        padding: 10px;
        border-bottom: 1px solid $color-gray-light;
        text-align: center;
        font-family: $garamond;
      }
      
      &.head {
        background: black;
        th {
          border-right: 1px solid white;
        }
        td {
          color: white;
          padding: 0;
          a {
            color: white;
            display: block;
            cursor: pointer;
            padding: 10px;
            &:hover {
              background: $color-green;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &--narrow {
    border-color: $color-gray-light;
    tbody {
      tr {
        border-color: $color-gray-light;
      }
      th {
        padding: 8px 10px;
        line-height: 1.5;
        font-weight: normal;
        width: 120px;
        font-weight: $bold;
        text-align: right;

        @include view-at(sp) {
          padding: 8px;
          width: 80px;
        }
      }
      td {
        padding: 8px 10px 8px 20px;
        line-height: 1.5;

        @include view-at(sp) {
          padding: 8px 10px 8px 15px;
        }
      }
    }
  }

  &-scroll {
    width: 100%;
    font-size: nth($font-size, 4);
    line-height: nth($line-height, 4);
    @include view-at(sp) {
      width: 650px;
      font-size: 14px;
    }
    thead {
      tr {
        border-top: 1px solid $color-gray-light;
        border-right: 1px solid $color-gray-light;
        border-left: 1px solid $color-gray-light;
        th {
          padding: 15px;
          line-height: 1.5;
          font-weight: bold;
          width: 30%;
          background-color: #F5F5F5;
          vertical-align: top;
          border-bottom: 1px solid $color-gray-light;
        }
        td {
          width: 35%;
          font-weight: $bold;
          padding: 15px 30px;
          border-bottom: 1px solid $color-gray-light;
          font-family: $garamond;
          border-right: 1px solid #c5c5c5;
        }
      }
    }
  }
}
