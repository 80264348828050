
/* --------------------------
color
-------------------------- */
$color-red: #e20013;
$color-red-dark: #D04255;
$color-red-light: #f8ebed;
$color-blue: #009dbf;
$color-blue-dark: #0b2b75;
$color-blue-light: #e8f5f3;
$color-gold: #dbc583;
$color-gold-light: #ede2c1;
$color-orange: #ff6c11;
$color-pink: #f8ebed;
$color-green: #009940;
$color-green-light: #eff8f2;
$color-white: #fff;
$color-black: #231815;

$color-gray: #ababab;
$color-gray-light: #c5c5c5;
$color-gray-dark: #717171;
$color-gray-snow: #f5f5f5;


/* --------------------------
spacing
-------------------------- */
$space: 100px, 40px, 30px, 20px;
$space-tab: 60px, 40px, 20px, 10px;
$space-sp: 50px, 30px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 36px, 22px, 18px, 16px, 14px, 12px;
$line-height: 68px, 30px, 30px, 22px, 20px, 18px;

$font-size-sp: 24px, 18px, 16px, 14px, 12px, 10px;
$line-height-sp: 40px, 36px, 32px, 22px, 16px, 16px;

$bold: 400;
$normal: 300;

$serif: YuMincho,'Yu Mincho',serif;
$sans: "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
$noto: noto-sans-cjk-jp, sans-serif;
$garamond: adobe-garamond-pro, YuMincho,'Yu Mincho',serif;

/* --------------------------
contents width
-------------------------- */
$contents-width: 1180px;
$contents-narrow-width: 760px;
$contents-padding: 20px;
$contents-padding-sp: 15px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  header,
  footer,
  modal
);
