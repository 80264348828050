
.p-gnav {
  @include view-at(tab) {
    display: none;
    background-color: $color-gray-snow;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow: scroll;
    padding-bottom: 80px;
  }

  &-list {
    display: flex;
    justify-content: center;

    @include view-at(tab) {
      flex-wrap: wrap;
      justify-content: flex-start;
      text-align: center;
    }

    &-item {
      display: flex;
      justify-content: center;

      @include view-at(tab) {
        padding: 10px;
        width: 100%;
        display: block;
      }

      @include view-at(sp) {
        padding: 5px;
      }
      
      > a {
        font-size: nth($font-size, 5);
        letter-spacing: 0.05em;
        text-decoration: none;
        line-height: 1;
        font-weight: 600;
        padding: 25px 30px;
        color: white;
        @include view-at(laptop) {
          padding: 15px 20px;
          font-size: 14px; 
          width: 100%;
          text-align: left;
          display: block;
        }
        @include view-at(tab) {
          color: black;
          position: relative;
          border: 1px solid;
        }
        @include view-at(sp) {
          padding: 15px 20px;
        }
        &:hover {
          color: black;
          background: white;
          opacity: 1;
        }
      }

      &.current {
        > a {
          color: black;
          background: white;
        }
      }

      &:hover {
        > .inner {
          height: auto;
          overflow: visible;
          opacity: 1;
        }
      }
    }
    .sub {
      width: 100%;
      margin-top: 40px;
    }
  }
}
