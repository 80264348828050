
.p-footer {
  padding: 80px 0 0;
  color: $color-gray-dark;

  @include view-at(tab) {
    padding: 40px 0 60px;
  }

  &-info {
    background: $color-gray-snow;
    margin-bottom: 40px;
    @include view-at(sp) {
      padding: 20px 0;
    }
    /* Google Mapを囲う要素 */
    .map {
      height: 300px;
      width: 100%;
    }
    ul {
      position: absolute;
      top: -30px;
      right: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      align-items: center;
      margin: 0 -10px;
      @include view-at(tab) {
        position: unset;
        margin-bottom: 10px;
      }
      @include view-at(sp) {
        margin: -5px;
      }
      li {
        padding: 10px;
        width: 50px;
        @include view-at(sp) {
          padding: 5px;
          width: 40px;
        }
      }
    }

    h3 {
      width: 250px;
      height: auto;
      margin-bottom: 20px;
      @include view-at(sp) {
        width: 200px;
        margin-bottom: 10px;
      }
    }

    &-btn {
      margin-top: 20px;
      a {
        @include view-at(sp) {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }

  &-link {
    font-size: 12px;
    font-family: $serif;
    margin-bottom: 25px;
    font-weight: bold;
    h3 {
      font-size: 14px;
      margin-bottom: 15px;
      font-weight: bold;
    }
    ul {
      li {
        margin-bottom: 15px;
        position: relative;
        padding-left: 15px;
        line-height: 1.3em;
        &::before {
          content: "-";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &-contents {
    background: $color-gray-snow;
    padding: 30px 0;
    &-inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      max-width: 700px;
      margin: 0 auto 30px;
    }
  }

  &-logo {
    max-width: 250px;
    @include view-at(sp) {
      max-width: 200px;
      margin: 0 auto 20px;
    }
  }

  &-cv {
    background: white;
    padding: 20px;
    width: 300px;
    display: block;
    font-size: 14px;
    text-align: center;
    @include view-at(sp) {
      width: 100%;
    }
    &:hover {
      opacity: 1;
      background: black;
      color: white;
    }
  }

  &-nav {
    ul {
      display: flex;
      justify-content: center;
      font-size: 12px;
      margin: 0 -30px;
      @include view-at(sp) {
        flex-wrap: wrap;
        margin: -5px;
        font-size: 10px;
        justify-content: flex-start;
      }
      li {
        padding: 0 30px;
        @include view-at(sp) {
          padding: 5px;
          width: 50%;
        }
        &::before {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          border-left: 3px solid black;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          padding-right: 10px;
          @include view-at(sp) {
            padding-right: 5px;
          }
        }
      }
    }
  }

  &-copyright {
    text-align: center;
    font-size: 12px;
    margin: 10px 0;
    @include view-at(sp) {
      font-size: 10px;
    }
  }
}
