.pro {
    overflow: hidden;
    .page-mv {
        background: url(../images/pro/mv.jpg) no-repeat;
        background-size: cover;
        background-position: center top;
        h2 {
            color: white;
            filter: drop-shadow(0 0 10px black);
        }
    }
    
    &-box {
        padding: 40px;
        background: -moz-linear-gradient(45deg, #ecedea, #f8f8f6 50%, #ecedea);
        background: -webkit-linear-gradient(45deg, #ecedea, #f8f8f6 50%, #ecedea);
        background: linear-gradient(45deg, #ecedea, #f8f8f6 50%, #ecedea);
        height: 100%;
        h3 {
            font-family: relation-one, sans-serif;
            font-weight: 400;
            font-size: 30px;
            text-align: center;
            margin-bottom: 20px;
        }
        p {
            line-height: 2em;
        }
    }

    &-info {
        li {
            &:nth-child(1) {
                background: url(../images/pro/bg.png) no-repeat;
                background-size: cover;
                color: white;
                padding: 120px 80px 80px;
                position: relative;
                @include view-at(sp) {
                    padding: 80px 15px 250px;
                }
                h2 {
                    position: absolute;
                    top: -20px;
                    left: -5px;
                    font-size: 60px;
                    margin-bottom: 40px;
                    @include view-at(sp) {
                        font-size: 40px;
                        top: -12px;
                    }
                    small {
                        padding-left: 30px;
                        @include view-at(sp) {
                            padding-left: 15px;
                        }
                    }
                }
                table {
                    max-width: 600px;
                    position: relative;
                    z-index: +1;
                    tr {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 10px;
                        line-height: 1.5em;
                        font-size: 14px;
                        @include view-at(sp) {
                            font-size: 12px;
                        }
                        th {
                            width: 150px;
                            padding-left: 20px;
                            @include view-at(sp) {
                                width: 100px;
                                padding-left: 0;
                            }
                        }
                        td {
                            width: calc(100% - 150px);
                            @include view-at(sp) {
                                width: calc(100% - 100px);
                            }
                        }
                    }
                }
                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 67%;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background: url(../images/pro/pro.png) no-repeat;
                    background-size: auto 100%;
                    background-position: bottom right;
                    @include view-at(sp) {
                        width: 80%;
                        background-size: 100%;
                    }
                }
            }
            &:nth-child(2) {
                background: url(../images/pro/pro.jpg) no-repeat;
                background-size: cover;
                background-position: center;
                position: relative;
                @include view-at(tab) {
                    height: 500px;
                }
                @include view-at(sp) {
                    height: 400px;
                }
                h2 {
                    position: absolute;
                    bottom: -20px;
                    right: -5px;
                    font-size: 40px;
                    @include view-at(sp) {
                        font-size: 30px;
                        bottom: -10px;
                    }
                }
            }
        }
    }
}