.restaurant {
    .page-mv {
        background: url(../images/restaurant/mv.jpg) no-repeat;
        background-size: cover;
        background-position: center top;
        h2 {
            color: white;
            filter: drop-shadow(0 0 6px black);
        }
    }
    &-bg {
        background: url(../images/restaurant/bg.png) no-repeat;
        background-size: cover;
        background-position: center top;
    }
    h3 {
        margin-bottom: 20px;
        &.en {
            font-size: 40px;
            font-family: relation-one, sans-serif;
            font-weight: 400;
            letter-spacing: 0.05em;
            color: #86763d;
            @include view-at(sp) {
                font-size: 30px;
            }
            &::before {
                content: "";
                display: block;
                width: 60px;
                height: 50px;
                background: url(../images/restaurant/icon.png) no-repeat;
                background-size: cover;
                margin: 0 auto;
            }
            small {
                font-size: 14px;
                font-family: $serif;
                padding-left: 10px;
                display: block;
                @include view-at(sp) {
                    font-size: 12px;
                }
            }
        }
    }

    h4 {
        margin: 8px 0 5px;
        font-weight: bold;
        line-height: 1.3em;
        @include view-at(sp) {
            font-size: 14px;
        }
    }

    table {
        display: block;
        tbody {
            display: block;
            tr {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                overflow: hidden;
                @include view-at(sp) {
                    font-size: 12px;
                }
                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
                th {
                    position: relative;
                    background: white;
                    padding-right: 10px;
                    max-width: 70%;
                    &::before {
                        content: "・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・";
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        z-index: -1;
                    }
                    @include view-at(sp) {
                        padding-right: 5px;
                        max-width: 60%;
                    }
                }
                td {
                    text-align: right;
                    padding-left: 10px;
                    background: white;
                    small {
                        font-size: 12px;
                        @include view-at(sp) {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }

    .txt-hide{
        display: none;
    }
    
    a.more {
        transition: .5s;
        -erbkit-transition: .5s;
        width: 250px;
        text-align: center;
        position: relative;
        &::after {
            position: absolute;
            top: 40%;
            right: 15px;
            display: block;
            width: 7px;
            height: 7px;
            margin: auto;
            content: '';
            transform: rotate(135deg);
            border-top: 2px solid #000;
            border-right: 2px solid #000;
            @include view-at(sp) {
                top: 20px;
                right: 20px;
                width: 7px;
                height: 7px;
            }
        }
        &.on-click::after {
            transform: rotate(-45deg);
        }
        &:hover {
            &::after {
                border-color: white;
            }
        }
    }
    
    .more-btn{
        transition: .5s;
        -erbkit-transition: .5s;
        width: 250px;
        text-align: center;
        position: relative;
        &::after {
            position: absolute;
            top: 40%;
            right: 15px;
            display: block;
            width: 7px;
            height: 7px;
            margin: auto;
            content: '';
            transform: rotate(135deg);
            border-top: 2px solid #000;
            border-right: 2px solid #000;
            @include view-at(sp) {
                top: 20px;
                right: 20px;
                width: 7px;
                height: 7px;
            }
        }
        &.on-click::after {
            transform: rotate(-45deg);
        }
        &:hover {
            &::after {
                border-color: white;
            }
        }
    }
    .more-btn:hover{
        cursor: pointer;
    }
}