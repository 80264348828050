/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {

  ul {
    list-style: none;
    margin: 0;
    padding: 20px 0;
    display: flex;

    li {
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
  
      @include view-at(sp) {
        font-size: 10px;
      }
  
      &::after {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        margin: 0 1.5em 0 1em;
        border-top: solid 1px black;
        border-right: solid 1px black;
        transform: rotate(45deg) translateY(-50%);
      }
      
      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }
}
