nav.cp_navi *, nav.cp_navi *:after, nav.cp_navi *:before {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
}
nav.cp_navi a {
	text-decoration: none;
}
nav.cp_navi {
	margin: 2em 0;
	text-align: center;
    font-family: $noto;
    font-weight: bold;
}
.cp_navi .cp_pagination {
	display: inline-block;
	margin-top: 2em;
	padding: 0 0.5em;
}
.cp_navi .cp_pagenum {
	font-size: 1em;
	line-height: 40px;
	display: block;
	float: left;
	transition: 400ms ease;
	letter-spacing: 0.1em;
	color: #37474F;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	margin: auto .5em;
}
.cp_navi .cp_pagenum:hover,
.cp_navi .cp_pagenum.current {
	font-weight: bold;
	color: #ffffff;
	background-color: $color-green;
	border-radius: 50%;
}
.cp_navi .cp_pagenum.prev,
.cp_navi .cp_pagenum.next {
    width: 50px;
}
.cp_navi .cp_pagenum.prev:hover,
.cp_navi .cp_pagenum.next:hover {
	color: $color-green;
	background-color: transparent;
	border-radius: 50%;
}
@media only screen and (max-width: 960px) {
	.cp_navi .cp_pagination {
		margin-top: 50px;
		padding: 0 10px;
	}
	.cp_navi .cp_pagenum {
	font-size: 0.8em;
	line-height: 25px;
	width: 45px;
	height: 25px;
	margin: auto .1em;
	border-radius: 0.3em;
}
	.cp_navi .cp_pagenum.prev,
	.cp_navi .cp_pagenum.next {
		padding: 0 25px 0 0;
        width: 50px;
	}
	.cp_navi .cp_pagenum:hover,
	.cp_navi .cp_pagenum.current {
		border-radius: 0.3em;
	}
	.cp_navi .cp_pagenum.prev:hover,
	.cp_navi .cp_pagenum.next:hover {
		border-radius: 0.3em;
	}
}
@media only screen and (min-width: 120px) and (max-width: 767px) {
	.cp_navi .cp_pagenum {
	display: none;
	border-radius: 0.3em;
	}
	.cp_navi .cp_pagenum:nth-of-type(2) {
	position: relative;
	padding-right: 20px;
	}
	.cp_navi .cp_pagenum:nth-of-type(2)::after {
	font-size: 1.2em;
	position: absolute;
	top: 0;
	left: 25px;
	content: '...';
	}
	.cp_navi .cp_pagenum:nth-child(-n+3),
	.cp_navi .cp_pagenum:nth-last-child(-n+3) {
		display: block;
	}
	.cp_navi .cp_pagenum:nth-last-child(-n+4) {
		padding: 0 5px;
	}
	.cp_navi .cp_pagenum:nth-last-child(-n+4)::after {
		content: none;
	}
	.cp_navi .cp_pagenum:hover,
	.cp_navi .cp_pagenum.current {
		border-radius: 0.3em;
	}
	.cp_navi .cp_pagenum.prev:hover,
	.cp_navi .cp_pagenum.next:hover {
		border-radius: 0.3em;
	}
}