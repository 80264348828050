.user {
    .page-mv {
        background: url(../images/user/mv.jpg) no-repeat;
        background-size: cover;
        background-position: center top;
    }

    &-btn {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        a {
            width: 250px;
            margin: 0 5px;
            display: inline-block;
            @include view-at(tab) {
                width: calc(33.3333% - 10px);
                margin: 0 5px;
            }
            @include view-at(sp) {
                width: 100%;
                margin: 5px 0;
            }
        }
    }
}