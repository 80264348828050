.facility {
    .page-mv {
        background: url(../images/facility/mv.jpg) no-repeat;
        background-size: cover;
        background-position: center top;
        h2 {
            color: white;
        }
    }
    &-bg {
        background: url(../images/facility/bg.png);
        display:flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
        > div {
            width: 100%;
        }
    }
    /*========= レイアウトのためのCSS ===============*/
    .box{
        padding: 77px 30px;
        @include view-at(tab) {
            padding: 50px 30px;
        }
        @include view-at(sp) {
            padding: 40px 15px;
        }
        h2 {
            font-size: 80px;
            color: white;
            margin-bottom: 20px;
            filter: drop-shadow(2px 0px 6px #333);
            @include view-at(tab) {
                font-size: 60px;
            }
            @include view-at(sp) {
                font-size: 30px;
                margin-bottom: 10px;
            }
        }
        h3 {
            color: white;
            filter: drop-shadow(2px 0px 6px #333);
            @include view-at(sp) {
                font-size: 14px;
            }
        }
    }
    #box1{
        background: url(../images/facility/01.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @include view-at(sp) {
            background: url(../images/facility/01-sp.jpg) no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    #box2{
        background: url(../images/facility/02.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @include view-at(sp) {
            background: url(../images/facility/02-sp.jpg) no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    #box3{
        background: url(../images/facility/03.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @include view-at(sp) {
            background: url(../images/facility/03-sp.jpg) no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    #box4{
        background: url(../images/facility/04.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @include view-at(sp) {
            background: url(../images/facility/04-sp.jpg) no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    #box5{
        background: url(../images/facility/05.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @include view-at(sp) {
            background: url(../images/facility/05-sp.jpg) no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    #box6{
        background: url(../images/facility/06.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @include view-at(sp) {
            background: url(../images/facility/06-sp.jpg) no-repeat;
            background-size: cover;
            background-position: center;
        }
    }


    /*========= ページネーションCSS ===============*/

    .pagination {
        position:fixed;
        right:20px;
        top: 50%;
        transform: translateY(-50%);
        font-size:1em;
        z-index: 10;
        list-style: none;
        a {
            display:block;
            height:20px;
            margin-bottom:5px;
            color:#fff;
            position:relative;
            padding:4px;
            .hover-text {
                position: absolute;
                right: 18px;
                top: 3px;
                opacity: 0;
                -webkit-transition: opacity 0.5s ease;
                transition: opacity 0.5s ease;
                font-size: 12px;
                font-family: noto-sans-cjk-jp, sans-serif;
                font-weight: bold;
                width: 150px;
                text-align: right;
            }
            &:hover .hover-text {
                opacity: 1;
            }
            &::after {
                -webkit-transition:box-shadow 0.5s ease;
                transition:box-shadow 0.5s ease;
                width:10px;
                height:10px;
                display: block;
                border-radius:50%;
                content:"";
                position: absolute;
                margin:auto;
                top:0;
                right:3px;
                bottom:0;
                border: 1px solid $color-gray;
                background: white;
            }
            &.active::after {
                background: black;
                opacity: 1;
                border: 1px solid black;
            }
        }
    }

    &-panel {
        
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                width: calc(100%/3);
                padding: 5px;
                @include view-at(sp) {
                    width: 50%;
                }
                a {
                    padding-top: 100%;
                    position: relative;
                    display: block;
                    &::after {
                        content: "";
                        display: block;
                        background: url(../images/top/grid08.jpg) no-repeat;
                        background-size: cover;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: all 0.5s;
                    }
                    &:hover {
                        opacity: 1;
                        &::after {
                            filter: brightness(0.5);
                        }
                    }
                    h2 {
                        text-align: center;
                        color: white;
                        z-index: +1;
                        text-shadow: 0 0 10px black;
                        font-size: 30px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 100%;
                        text-align: center;
                        small {
                            text-shadow: 0 0 5px black;
                        }
                        @include view-at(tab) {
                            font-size: 26px;
                            small {
                                font-size: 14px;
                            }
                        }
                        @include view-at(sp) {
                            font-size: 16px;
                            small {
                                font-size: 12px;
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    a {
                        &::after {
                            background: url(../images/top/grid06.jpg) no-repeat;
                            background-size: cover;
                        }
                    }
                }
                &:nth-child(3) {
                    a {
                        &::after {
                            background: url(../images/top/grid07.jpg) no-repeat;
                            background-size: cover;
                        }
                    }
                }
            }
        }
    }
}