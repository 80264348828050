/* --------------------------
非表示切り替え
-------------------------- */

@include view-at(pc){
  .u-hide--pc {
    display: none !important;
  }
}

@include view-at(tab) {
  .u-hide--tab {
    display: none !important;
  }
}

@include view-at(laptop) {
  .u-hide--laptop {
    display: none !important;
  }
}

@include view-at(sp) {
  .u-hide--sp {
    display: none !important;
  }
}

.u-visible--tab {
  display: none !important;
}
@include view-at(tab) {
  .u-visible--tab {
    display: block !important;
  }
}