.shop {
    .page-mv {
        background: url(../images/shop/mv.jpg) no-repeat;
        background-size: cover;
        background-position: center top;
        h2 {
            color: white;
            filter: drop-shadow(0 0 10px black);
        }
    }
}