/* --------------------------
フォーム
-------------------------- */


form {
  p {
      font-weight: bold;
      font-size: 14px;
      @include view-at(sp) {
          font-size: 12px;
          padding: 0 10px;
      }
  }
  .form-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: -10px;
      @include view-at(sp) {
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-bottom: 30px;
      }
      p {
          padding: 10px;
          @include view-at(sp) {
              width: 100%;
              padding: 0 10px;
          }
      }
  }
  select, input[type="text"] {
      background: none;
      border: 1px solid white;
      padding: 8px 10px;
      border-radius: 0;
      color: white;
      min-width: 100px;
      border-radius: 0;
      outline: none;
      font-family: $sans;
      height: 35px !important;
      @include view-at(sp) {
          width: 100%;
      }
  }
  input.send {
      width: auto;
      background: white;
      color: black;
      padding: 8px 30px;
      font-weight: bold;
      font-family: $serif;
      cursor: pointer;
  }
  select {
      option {
          background: white;
          color: black;
          border-radius: none;
          font-weight: bold;
          &:checked {
              background: black;
              color: white;
          }
      }
  }
  input.hasDatepicker {
      position: relative;
      cursor: pointer;
      background: url(../images/common/icon-calendar.png);
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: 95% center;
      padding-top: 10px;
  }
  input[type="submit"] {
      appearance: none;
      -webkit-appearance: none;
  }
  .form-price {
      select {
          @include view-at(sp) {
              width: 44%;
          }
      }
  }
}


.ui-widget.ui-widget-content {
  border: 1px solid #c5c5c5;
  border-radius: unset;
}

.ms {
  &-choice {
    background: transparent !important;
    border-radius: unset !important;
    border-color: white !important;
    padding: 8px 10px !important;
    height: 35px !important;
    color: white !important;
    line-height: 35px !important;
    position: relative;
    font-family: $sans;
    &::after {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg) translateY(-50%);
      position: absolute;
      top: 41%;
      right: 10px;
    }
    span {
      padding-left: 15px !important;
      &.placeholder {
        color: white !important;
      }
    }
  }
  &-drop {
    border-radius: unset !important;
    border: none !important;
    font-family: $sans;
    ul {
      padding: 5px 0;
      li {
        input[type="checkbox"] {
          margin-top:0;
        }
      }
    }
  }
}