/* --------------------------
リンク
-------------------------- */

.c-link {
  color: $color-blue;

  &--block {
    display: block;
    text-decoration: none;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--none {
    text-decoration: none;
  }
}
