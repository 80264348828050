.access {
    .page-mv {
        background: url(../images/access/mv.jpg) no-repeat;
        background-size: cover;
        background-position: center top;
        h2 {
            color: white;
        }
    }
    /* Google Mapを囲う要素 */
    .map2 {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 65%; /* 比率を4:3に固定 */
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}