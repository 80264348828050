/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
  display: none;

  @include view-at(tab) {
    display: block;
    padding: 0 15px;
  }

  &-trigger,
  &-trigger span {
    display: inline-block;
    transition: all .2s;
    box-sizing: border-box;
  }

  &-trigger {
    position: relative;
    width: 34px;
    height: 26px;

    &-label {
      display: block;
      font-size: 8px;
      letter-spacing: 1.1px;
      position: absolute;
      left: 0;
      right: 0;
      top: 20px;
      margin: auto;
      text-align: center;
      width: 100%;
      background-color: transparent;
      height: auto;
      color: $color-white;
    }
  }

  &-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: black;
  }

  &-trigger span:nth-of-type(1) {
    top: 0;
  }

  &-trigger span:nth-of-type(2) {
    top: 11px;
  }

  &-trigger span:nth-of-type(3) {
    bottom: 0;
  }

  &-trigger.active span:nth-of-type(1) {
    -webkit-transform: translateY(11px) translateX(1px) rotate(-45deg);
    transform: translateY(11px) translateX(1px) rotate(-45deg);
  }

  &-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }

  &-trigger.active span:nth-of-type(3) {
    -webkit-transform: translateY(-11px) translateX(1px) rotate(45deg);
    transform: translateY(-11px) translateX(1px) rotate(45deg);
  }

  &-recruit {
    right: 20px;
  }
}
